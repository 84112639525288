import React, {Component} from "react";
import {
    Row,
    Card,
    CardBody,
    Container,
    Button, ButtonGroup,
} from "reactstrap";
import GoogleMapReact from 'google-map-react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {listCourses, setCourseStatus} from "../../../store/manager/actions";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

class CoursePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            paginator: {loading: true, page: 0, countRows: 0, limit: 50, maxPages: 0},
            date_start: (moment().startOf('month').toDate()),
            date_end: (moment().endOf('month').toDate()),
            date_current: new Date(),
            form_edit: {
                date_start: moment().startOf('month').format("DD/MM/YYYY"),
                date_end: moment().endOf('month').format("DD/MM/YYYY"),
            },
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listCourses(page + 1, this.state.form_edit.date_start, this.state.form_edit.date_end).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page = result.paginator.page;
                paginator.maxPages = result.paginator.maxPages;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: [...this.state.rows, ...result.list], paginator: paginator});
            }
        });
    }

    renderCourse = (course) => {
        let text = [];
        if (typeof course.client !== "undefined" && course.client !== null)
            text.push(<small>{course.client.firstname} {course.client.lastname} {course.client.email}</small>)
        if (typeof course.distance !== "undefined" && course.distance !== null)
            text.push(<small>Distanza: {course.distance}</small>)

        let coords = course.course.map((cords, c) => {
            return {lat: cords.lat, lng: cords.lon}
        });
        if (coords.length < 2)
            return;

        let cords_text;
        if (coords.length % 2 !== 1) {
            let mid = Math.floor(coords.length / 2) - 1;
            cords_text = {
                lat: (coords[mid].lat + coords[mid + 1].lat) / 2,
                lng: (coords[mid].lng + coords[mid + 1].lng) / 2
            };
        } else {
            let mid = Math.floor(coords.length / 2);
            cords_text = {
                lat: coords[mid].lat,
                lng: coords[mid].lng
            };
        }

        let button = <Button onClick={() => {
            this.setNotValid(course.id)
        }} type="button" size={"sm"} color="danger">Imposta "Non valido"</Button>
        if (!course.is_valid)
            button = <Button onClick={() => {
                this.setValid(course.id)
            }} type="button" size={"sm"} color="success">Imposta "Valido"</Button>
        return <Card className={'col-md-6'}>
            <span><b>Percorso #{course.id}</b><br/></span>
            <div style={{height: '300px', width: '100%'}}>
                <GoogleMapReact
                    key={course.id}
                    bootstrapURLKeys={{key: "AIzaSyAi605IDjNIDTzD4KhD3gaYrlPbukWuM9c"}}
                    defaultCenter={cords_text}
                    defaultZoom={10}
                    onGoogleApiLoaded={({map, maps}) => {
                        let polylineObj = new maps.Polyline({
                            path: coords,
                            strokeColor: "#51b270",
                            strokeOpacity: 0.8,
                            strokeWeight: 10,
                        });
                        polylineObj.setMap(map);
                        const bounds = new maps.LatLngBounds();

                        let point1 = new maps.LatLng(course.lat_start, course.lon_start);
                        let point2 = new maps.LatLng(course.lat_end, course.lon_end);
                        bounds.extend(point1);
                        bounds.extend(point2);
                        map.fitBounds(bounds)
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}/>
            </div>
            {text}
            <small>Data: {course.date_start}</small>
            <div align={"right"} style={{padding: 4}}>
                {button}
            </div>
        </Card>
    }

    setValid = (id) => {
        setCourseStatus(id, "true").then(() => {
            let rows = this.state.rows;
            for (let i in rows) {
                if (rows[i].id === id)
                    rows[i].is_valid = true;
            }
            this.setState({rows: rows});
        });
    }

    setNotValid = (id) => {
        setCourseStatus(id, "false").then(() => {
            let rows = this.state.rows;
            for (let i in rows) {
                if (rows[i].id === id)
                    rows[i].is_valid = false;
            }
            this.setState({rows: rows});
        });
    }

    renderCourses = () => {
        let list = [];

        for (let i in this.state.rows)
            list.push(this.renderCourse(this.state.rows[i]));
        let btn = "";
        if (this.state.paginator.page !== this.state.paginator.maxPages) {
            btn = <Button color="info" onClick={() => this.loadList(this.state.paginator.page)}>Mostra
                altro</Button>
        }
        return <div><Row>{list}</Row>
            <div align={"center"}>{btn}</div>
        </div>;
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start") {
            date_start = date;
            date_end = moment(date).add('1', "month").toDate();
        } else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Percorsi"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare i percorsi</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => {
                                                    this.setState({rows: []}, this.loadList(0))
                                                }} color={"success"}>Aggiorna</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {this.renderCourses()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(CoursePage));
