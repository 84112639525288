import React, {Component} from "react";
import {
    Card, CardBody, CardText, Container, Button, Label, Row, Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getSocietyListAreas, editArea, getMapAreas, regulationFileUpload} from '../../../store/actions';
import {AvForm, AvField} from 'availity-reactstrap-validation';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import "../society.scss";

const defaultProps = {
    center: {
        lat: 42.45,
        lng: 12.96
    },
    zoom: 6
};

class SocZoneListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome area", field: "name", width: 160},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 140},
                {headerName: "Province", field: "province", width: 140},
                {headerName: "Indirizzo", field: "address", width: 140},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 120},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: "Regolamento",
                    width: 200,
                    field: 'fishing_regulation',
                    renderCell: (params) => {
                        return <Row>
                            <Col>
                                <Button onClick={() => this.fileButton(params.getValue(params.id, "id"), "area")}
                                        type="button"
                                        color="secondary">File</Button>
                            </Col>
                            <Col>
                                {(params.formattedValue !== null) ? ((params.formattedValue.document !== null) ?
                                    <a href={global.baseUrlImg + params.formattedValue.document} alt="" target="_blank"
                                       max-height="100" width="100">Regolamento</a> : 'Nessun file') : 'Nessun file'}
                            </Col>
                        </Row>
                    }
                },
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.actionButton(params)} type="button"
                                color="primary">Modifica</Button>
                    )
                }
            ],
            columnsZone: [
                {
                    headerName: "Area di appartenenza", field: "area", width: 240, renderCell: (params) => {
                        return params.formattedValue.name;
                    }
                },
                {
                    headerName: "Nome zona", field: "micro_area", width: 360, renderCell: (params) => {
                        return params.formattedValue.name;
                    }
                },
                {
                    headerName: "Regolamento",
                    width: 200,
                    field: 'id',
                    renderCell: (params) => {
                        let fishing_regulation = params.getValue(params.id, "micro_area").fishing_regulation;
                        return <Row>
                            <Col>
                                <Button onClick={() => this.fileButton(params.getValue(params.id, "id"), "micro_area")}
                                        type="button"
                                        color="secondary">File</Button>
                            </Col>
                            <Col>
                                {(fishing_regulation !== null) ? ((fishing_regulation.document !== null) ?
                                    <a href={global.baseUrlImg + fishing_regulation.document} alt="" target="_blank"
                                       max-height="100" width="100">Regolamento</a> : 'Nessun file') : 'Nessun file'}
                            </Col>
                        </Row>
                    }
                }
            ],
            rows: [],
            rowsZone: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorZone: {loading: false, countRows: 400, limit: 50},
            markers: [],
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null,
            lat: 42.45,
            lon: 12.96,
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async componentDidMount() {
        this.loadListAreas();
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = async () => {
        var formData = new FormData();
        formData.append("file", this.state.imageUpload);
        if (this.state.typeUpload === "area")
            formData.append("id_area", this.state.uploadId);
        else
            formData.append("id_micro_area", this.state.uploadId);

        let result = await regulationFileUpload(formData);
        if (typeof result.error === "undefined") {
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null}, () => {
                global.notify("Immagine caricata con successo", "success");
                this.loadListAreas();
            });
        } else {
            global.notify("Errore durante il caricamento: " + result.error, "error");
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    fileButton = (id, type) => {
        this.setState({modal: true, uploadId: id, typeUpload: type});
    }

    loadListAreas = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        getSocietyListAreas().then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                if (this.state.map !== null)
                    this.handleApiLoaded(this.state.map, this.state.maps, result.list);
                let micro_areas = [];
                for (let i in result.list) {
                    for (let j in result.list[i].micro_areas)
                        micro_areas.push({
                            id: result.list[i].micro_areas[j].id,
                            area: result.list[i],
                            micro_area: result.list[i].micro_areas[j]
                        });
                }
                let paginatorZone = {loading: false, countRows: Object.keys(micro_areas).length, limit: 50};

                this.setState({
                    rows: result.list,
                    rowsZone: micro_areas,
                    paginator: paginator,
                    paginatorZone: paginatorZone
                });

            }
        })
    }

    actionButton = (params) => {
        window.location.href = '/zone/edit/' + params.row.id;
    }

    handleApiLoaded = (map, maps, row) => {
        let arr_markers = [];
        for (let i in row) {
            let area = row[i];
            if (typeof this.state.markers.find(m => {
                return m.id === area.id
            }) !== "undefined")
                continue;

            arr_markers.push({id: area.id});
            new maps.Marker({
                animation: maps.Animation.DROP,
                map: map,
                position: new maps.LatLng(area.lat, area.lon),
            });

            if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                let polygon = [];
                for (let i in area.area_cords)
                    polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                let polygonObj = new maps.Polygon({
                    paths: polygon,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                });
                polygonObj.setMap(map);
            }

            for (let i in area.micro_areas) {
                let microarea = area.micro_areas[i];
                let polygon = [];
                for (let k in microarea.area_cords)
                    polygon.push({lat: microarea.area_cords[k].lat, lng: microarea.area_cords[k].lon});
                let color = "#" + ((1 << 24) * Math.random() | 1).toString(16);
                let polygonObj = new maps.Polygon({
                    paths: polygon,
                    strokeColor: color,
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.45,
                    editable: false,
                    draggable: false
                });
                polygonObj.setMap(map);
            }
        }
        this.setState({markers: [...arr_markers, ...this.state.markers]})
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = await editArea(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Area modificata con successo", "success");
            this.loadListAreas(this.state.paginator.page);
            this.setState({flag_edit: false});
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    renderMap() {
        return <Card body>
            <div style={{height: '60vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAi605IDjNIDTzD4KhD3gaYrlPbukWuM9c"}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps}, () => this.loadListAreas(this.state.paginator.page))
                        // this.handleApiLoaded(map, maps, [])
                    }}
                />
            </div>
        </Card>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Zone"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <h4>Lista aree</h4>
                                    <div style={{height: '40vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                    <h4>Lista zone</h4>
                                    <div style={{height: '40vh', width: '100%', marginTop: 6}}>
                                        <DataGrid
                                            rows={this.state.rowsZone}
                                            columns={this.state.columnsZone}
                                            pagination
                                            pageSize={this.state.paginatorZone.limit}
                                            rowsPerPageOptions={[this.state.paginatorZone.limit]}
                                            rowCount={this.state.paginatorZone.countRows}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        {this.renderMap()}
                        <Modal
                            isOpen={this.state.modal}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Carica file</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli file</h1>
                                        <input type="file" name="file" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>{' '}
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getSocietyListAreas, getMapAreas})(SocZoneListPage));
