import React, {Component} from "react";
import {Card, CardBody, CardText, Container, Label} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getSocietyListAreas, editArea, getMapAreas} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {listAreaSociety, societyInfo} from "../../store/manager_society/actions";

const defaultProps = {
    center: {
        lat: 42.45,
        lng: 12.96
    },
    zoom: 6
};

class SocietyZoneListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome area", field: "name", width: 160},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 140},
                {headerName: "Province", field: "province", width: 140},
                {headerName: "Indirizzo", field: "address", width: 140},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 120},
                {headerName: "Telefono", field: "phone", width: 140},
            ],
            columnsZone: [
                {
                    headerName: "Area di appartenenza", field: "area", width: 240, renderCell: (params) => {
                        return params.formattedValue.name;
                    }
                },
                {
                    headerName: "Nome zona", field: "micro_area", width: 360, renderCell: (params) => {
                        return params.formattedValue.name;
                    }
                },
            ],

            form_edit: {},
            flag_edit: false,
            rows: [],
            rowsZone: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorZone: {loading: false, countRows: 400, limit: 50},
            markers: [],
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null,
            lat: 42.45,
            lon: 12.96,
            society: null
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            societyInfo(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                this.setState({society: result.society}, () => this.loadListAreas())
            });
        }
    }

    loadListAreas = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        listAreaSociety(this.state.society.id).then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                if (this.state.map !== null)
                    this.handleApiLoaded(this.state.map, this.state.maps, result.list);
                let micro_areas = [];
                for (let i in result.list) {
                    for (let j in result.list[i].micro_areas)
                        micro_areas.push({
                            id: result.list[i].micro_areas[j].id,
                            area: result.list[i],
                            micro_area: result.list[i].micro_areas[j]
                        });
                }
                let paginatorZone = {loading: false, countRows: Object.keys(micro_areas).length, limit: 50};

                this.setState({
                    rows: result.list,
                    rowsZone: micro_areas,
                    paginator: paginator,
                    paginatorZone: paginatorZone
                });

            }
        })
    }

    renderSociety() {
        if (this.state.society === null)
            return;
        let society = this.state.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    handleApiLoaded = (map, maps, row) => {
        let arr_markers = [];
        for (let i in row) {
            let area = row[i];
            if (typeof this.state.markers.find(m => {
                return m.id === area.id
            }) !== "undefined")
                continue;

            arr_markers.push({id: area.id});
            new maps.Marker({
                animation: maps.Animation.DROP,
                map: map,
                position: new maps.LatLng(area.lat, area.lon),
            });

            if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                let polygon = [];
                for (let i in area.area_cords)
                    polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                let polygonObj = new maps.Polygon({
                    paths: polygon,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                });
                polygonObj.setMap(map);
            }

            for (let i in area.micro_areas) {
                let microarea = area.micro_areas[i];
                let polygon = [];
                for (let k in microarea.area_cords)
                    polygon.push({lat: microarea.area_cords[k].lat, lng: microarea.area_cords[k].lon});
                let color = "#" + ((1 << 24) * Math.random() | 1).toString(16);
                let polygonObj = new maps.Polygon({
                    paths: polygon,
                    strokeColor: color,
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.45,
                    editable: false,
                    draggable: false
                });
                polygonObj.setMap(map);
            }
        }
        this.setState({markers: [...arr_markers, ...this.state.markers]})
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = await editArea(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Area modificata con successo", "success");
            this.loadListAreas(this.state.paginator.page);
            this.setState({flag_edit: false});
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    renderMap() {
        return <Card body>
            <div style={{height: '60vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAi605IDjNIDTzD4KhD3gaYrlPbukWuM9c"}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps}, () => this.loadListAreas(this.state.paginator.page))
                        // this.handleApiLoaded(map, maps, [])
                    }}
                />
            </div>
        </Card>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista zone dell'ente"/>
                        {this.state.society === null ? <div></div> :
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <Card>
                                        <CardBody>
                                            <div className="form-group">
                                                <Label for="society"><b>{this.state.society.name}</b></Label>
                                                {this.renderSociety()}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className={"col-md-12"}>
                                    <Card>
                                        <CardBody>
                                            <div className="form-group">
                                                <div style={{height: '40vh', width: '100%'}}>
                                                    <DataGrid
                                                        rows={this.state.rows}
                                                        columns={this.state.columns}
                                                        pagination
                                                        pageSize={this.state.paginator.limit}
                                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                                        rowCount={this.state.paginator.countRows}
                                                        loading={this.state.paginator.loading}
                                                    />
                                                </div>
                                                <div style={{height: '40vh', width: '100%', marginTop: 6}}>
                                                    <DataGrid
                                                        rows={this.state.rowsZone}
                                                        columns={this.state.columnsZone}
                                                        pagination
                                                        pageSize={this.state.paginatorZone.limit}
                                                        rowsPerPageOptions={[this.state.paginatorZone.limit]}
                                                        rowCount={this.state.paginatorZone.countRows}
                                                        loading={this.state.paginator.loading}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className={"col-md-12"}>
                                    {this.renderMap()}
                                </div>
                            </div>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getSocietyListAreas, getMapAreas})(SocietyZoneListPage));
