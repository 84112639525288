import React, {Component} from "react";
import {
    Row,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, ButtonGroup
} from "reactstrap";
import GoogleMapReact from 'google-map-react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {
    getMapPickings,
    listPickings,
    setPickingStatus
} from "../../../store/manager/actions";
import DatePicker from "react-datepicker";
import moment from "moment";

class PickingPage extends Component {
    static defaultProps = {
        center: {
            lat: 42.45,
            lng: 12.96
        },
        zoom: 5.5
    };

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, page: 0, countRows: 0, limit: 50, maxPages: 0},
            markers: [],
            image: null,
            modal: null,
            date_start: (moment().startOf('month').toDate()),
            date_end: (moment().endOf('month').toDate()),
            date_current: new Date(),
            form_edit: {
                date_start: moment().startOf('month').format("DD/MM/YYYY"),
                date_end: moment().endOf('month').format("DD/MM/YYYY"),
            },
        };
    }

    handleClose = () => this.setState({modal: null});

    async componentDidMount() {
        this.loadList(0);
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.handleApiLoaded(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let bounds = map.getBounds();
        getMapPickings(bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (typeof this.state.markers.find(m => {
                    return m.id === area.id
                }) !== "undefined")
                    continue;

                arr_markers.push({id: area.id});
                let marker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });
                // maps.event.addListener(marker, 'click', () => {
                //     window.location.href = '/zone/edit/' + area.id
                // });
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    renderMap() {
        return <Card body>
            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAi605IDjNIDTzD4KhD3gaYrlPbukWuM9c"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                />
            </div>
        </Card>
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listPickings(page + 1, this.state.form_edit.date_start, this.state.form_edit.date_end).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page = result.paginator.page;
                paginator.maxPages = result.paginator.maxPages;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    actionButton = (value) => {
        window.location.href = '/zone/edit/' + value;
    }

    fileButton = (id) => {
        this.setState({modal: true, uploadId: id});
    }

    renderPicking = (pick) => {
        let text = [];
        if(typeof pick.client!== "undefined" && pick.client !== null)
            text.push(<small>{pick.client.firstname} {pick.client.lastname} {pick.client.email}</small>)

        if (pick.type !== null)
            text.push(<span>Tipologia: {pick.type.name}</span>)
        if (pick.weight !== null)
            text.push(<span>Peso: {pick.weight}</span>)
        if (pick.quantity !== null)
            text.push(<span>Quantità: {pick.quantity}</span>)
        if (pick.note !== null)
            text.push(<span>note: {pick.note}</span>)
        if (pick.img !== null)
            text.push(<Button size={"sm"} color={"info"} onClick={() => this.setState({modal: pick})}>Immagine</Button>)

        let button = <Button onClick={() => {
            this.setNotValid(pick.id)
        }} type="button" size={"sm"} color="danger">Imposta "Non valido"</Button>
        if (!pick.is_valid)
            button = <Button onClick={() => {
                this.setValid(pick.id)
            }} type="button" size={"sm"} color="success">Imposta "Valido"</Button>

        return <Card className={'col-md-3'}>
            <span><b>Cattura #{pick.id}</b><br/></span>
            {text}
            <small>Data raccolta: {pick.date_capture}</small>
            <div align={"center"} style={{padding: 4, marginTop: "auto"}}>
                {button}
            </div>
        </Card>
    }

    setValid = (id) => {
        setPickingStatus(id, "true").then(() => {
            let rows = this.state.rows;
            for (let i in rows) {
                if (rows[i].id === id)
                    rows[i].is_valid = true;
            }
            this.setState({rows: rows});
        });
    }

    setNotValid = (id) => {
        setPickingStatus(id, "false").then(() => {
            let rows = this.state.rows;
            for (let i in rows) {
                if (rows[i].id === id)
                    rows[i].is_valid = false;
            }
            this.setState({rows: rows});
        });
    }

    renderPickings = () => {
        let list = [];

        for (let i in this.state.rows)
            list.push(this.renderPicking(this.state.rows[i]));
        let btn = "";
        if (this.state.paginator.page !== this.state.paginator.maxPages) {
            btn = <Button color="info" onClick={() => this.loadList(this.state.paginator.page)}>Mostra
                altro</Button>
        }
        return <div><Row>{list}</Row>
            <div align={"center"}>{btn}</div>
        </div>;
    }

    renderModal = () => {
        if (this.state.modal !== null)
            return <Modal isOpen={true}>
                <ModalHeader>Immagine</ModalHeader>
                <ModalBody>
                    <img src={global.baseUrlImg + this.state.modal.img} style={{width: "100%", height: "auto"}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
                </ModalFooter>
            </Modal>
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start") {
            date_start = date;
            date_end = moment(date).add('1', "month").toDate();
        } else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Raccolte"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare i percorsi</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadList(0)}
                                                        color={"success"}>Aggiorna</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {this.renderPickings()}
                            </CardBody>
                        </Card>
                        {this.renderModal()}
                        {this.renderMap()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(PickingPage));
