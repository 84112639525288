import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {listCoupon} from "../../../store/manager/coupon/actions";

class BlogListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "ID", field: "id", width: 60},
                {headerName: "Codice coupon", field: "code", width: 240},
                {headerName: "Descrizione", field: "description", width: 240},
                {headerName: " ", field: "body", width: 180, renderCell: (params)=> (
                            <Button color="warning" type="button"
                                    onClick={() => window.location.href = '/coupon/edit/'+  params.id}>Modifica</Button>
                    )},

            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listCoupon(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista coupon"/>
                        <Card>
                            <CardBody>
                                <div align={"right"}>
                                    <Button color="success" type="button"
                                            onClick={() => window.location.href = '/coupon/add'}>Aggiungi coupon</Button>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(BlogListPage));
