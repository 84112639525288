import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button, Badge, ButtonGroup, CardTitle, CardText
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Breadcrumb from '../../../components/Common/Breadcrumb';

import {AvField, AvForm} from "availity-reactstrap-validation";
import {addCoupon, editCoupon, infoCoupon} from "../../../store/manager/coupon/actions";
import {DataGrid} from "@material-ui/data-grid";
import {getListAreas} from "../../../store/manager/zone/actions";
import {listAreaTickets} from "../../../store/manager/products/actions";

class CouponPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 160, renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 160}],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Prezzo", field: "price", width: 160},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 160, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "ticket_residence_type", width: 180, renderCell: (params) => {
                        if (params.value === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.value.name}</Badge>)
                    }
                },
            ],
            rows: [],
            rowsTicket: [],
            showTable: 'AREA',
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            area: null,
            micro_area: null,
            ticket: null,
            coupon: null,
            form_edit: {
                code: null,
                description: null,
                discount_price: null,
                discount_percentage: null,
                availability: null
            }
        };
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            infoCoupon(this.props.match.params.id).then((result) => {
                this.setState({coupon: result.coupon, form_edit: result.coupon});
                if (result.coupon.areas_validity !== null)
                    this.setState({area: result.coupon.areas_validity});
                if (result.coupon.ticket_validity !== null)
                    this.setState({ticket: result.coupon.ticket_validity});
            });
        }
        this.showTable('AREA');
    }

    async handleValidSubmit(event, values, edit = false) {
        let data = values;
        let formResult = null;
        if (edit) {
            data.id = this.state.coupon.id;
            if (this.state.area !== null)
                data.id_area = this.state.area.id;
            if (this.state.ticket !== null)
                data.id_ticket = this.state.ticket.id;

            formResult = await editCoupon(data);
            if (typeof formResult.blog !== 'undefined') {
                global.notify("Coupon modificato con successo", "success");
            } else if (typeof formResult.error !== 'undefined') {
                global.notify("Errore con la modifica: " + formResult.error, "danger");

                this.setState({error: formResult.error});
            } else {
                this.setState({error: 'Errore generico'});
            }
        } else {
            if (this.state.area !== null)
                data.id_area = this.state.area.id;
            if (this.state.ticket !== null)
                data.id_ticket = this.state.ticket.id;
            formResult = await addCoupon(data);
            if (typeof formResult.coupon !== 'undefined') {
                this.setState({coupon: formResult.coupon, form_edit: formResult.coupon});
                global.notify("Coupon aggiunto con successo", "success");
            } else if (typeof formResult.error !== 'undefined') {
                global.notify("Errore con l'aggiunta: " + formResult.error, "danger");

                this.setState({error: formResult.error});
            } else {
                this.setState({error: 'Errore generico'});
            }
        }
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    loadListTicket = (page) => {
        let paginatorTicket = this.state.paginatorTicket;
        paginatorTicket.loading = true;
        this.setState({paginatorTicket: paginatorTicket});

        listAreaTickets(this.state.area.id, page + 1).then(result => {
            paginatorTicket.loading = false;
            if (!result) {
                paginatorTicket.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginatorTicket});
            } else {
                paginatorTicket.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicket: result.list, paginatorTicket: paginatorTicket});
            }
        })
    }


    showTable = (state) => {
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'TICKET' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state === 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTable) {
            case "AREA":
                this.setState({
                    area: row,
                }, () => this.loadListTicket(0));
                break;
            case "TICKET":
                this.setState({ticket: row});
                break;
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket"
                                    breadcrumbItem={(this.state.coupon !== null) ? "Modifica coupon" : "Aggiunta coupon"}/>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.coupon !== null)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>

                                        <div className="form-group col-md-12">
                                            <AvField name="code" label="Codice del coupon (opzionale)"
                                                     value={this.state.form_edit.code}
                                                     className="form-control"
                                                     placeholder="Codice del coupon (opzionale)"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione del coupon"
                                                     value={this.state.form_edit.description}
                                                     className="form-control" placeholder="Descrizione del coupon"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="discount_price" label="Sconto sul prezzo del ticket"
                                                     value={this.state.form_edit.discount_price}
                                                     className="form-control" placeholder="Sconto sul prezzo del ticket"
                                                     type="text"/>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="discount_percentage" label="Sconto in percentuale sul ticket"
                                                     value={this.state.form_edit.discount_percentage}
                                                     className="form-control"
                                                     placeholder="Sconto in percentuale sul ticket"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="availability" label="Disponibilità del coupon"
                                                     value={this.state.form_edit.availability}
                                                     className="form-control" placeholder="Disponibilità del coupon"
                                                     type="number"/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color={(this.state.flag_edit) ? "warning" : "success"}>{(this.state.coupon !== null) ? "Modifica coupon" : "Crea coupon"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Limita il ticket per un area specifica o un ticket<small>(opzionale)</small></h5>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                                <Button color={"danger"} onClick={() => {
                                                    this.setState({area: null, ticket: null})
                                                }}>
                                                    Rimuovi
                                                </Button>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.ticket !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Ticket</CardTitle>
                                                <CardText>Nome
                                                    ticket: {this.state.ticket.name}<br/> {this.state.ticket.price},
                                                    {this.state.ticket.date_start_validity} {this.state.ticket.date_end_validity} {this.state.ticket.type}
                                                </CardText>
                                                <Button color={"danger"} onClick={() => {
                                                    this.setState({ticket: null})
                                                }}>
                                                    Rimuovi
                                                </Button>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>

                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showTable('AREA')}
                                            color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                        <Button
                                            onClick={() => this.showTable('TICKET')}
                                            color={(this.state.showTable === 'TICKET') ? "light" : "primary"}>Ticket</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'AREA'}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'TICKET'}>
                                    <DataGrid
                                        rows={this.state.rowsTicket}
                                        columns={this.state.columnsTicket}
                                        pagination
                                        pageSize={this.state.paginatorTicket.limit}
                                        rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                                        rowCount={this.state.paginatorTicket.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageTicketChange}
                                        loading={this.state.paginatorTicket.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps,
    {}
)(CouponPage));
