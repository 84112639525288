import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ListGroupItem,
    Badge,
    ListGroup
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {createEditor, Descendant} from 'slate'
import {Slate, Editable, withReact} from 'slate-react'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    addTicket,
    editTicket,
    infoTicket,
    getListAreas,
    ticketResidenceList,
    disableTicket,
    deleteTicket,
    ticketShareSociety,
    ticketShareListSociety,
    getSocieties,
    ticketExtraDataDelete,
    ticketExtraDataAdd,
    listShopPackage,
    removeShopPackageTicket,
    addShopPackageTicket,
    getListServiceTag,
    uploadImageArea,
    uploadImageTicket,
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import InputMask from "react-input-mask";

var moment = require('moment');

class TicketAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: "Zone",
                    width: 150,
                    field: 'id',
                    renderCell: (params) => (Object.keys(params.getValue(params.id, "micro_areas")).length > 0 ?
                        <Button type="button"
                                color="primary">{Object.keys(params.getValue(params.id, "micro_areas")).length}</Button> : " ")
                },
                {
                    headerName: "Gruppo zone",
                    width: 150,
                    field: 'id_',
                    renderCell: (params) => (Object.keys(params.getValue(params.id, "micro_areas_group")).length > 0 ?
                        <Button type="button"
                                color="primary">{Object.keys(params.getValue(params.id, "micro_areas_group")).length}</Button> : " ")
                }],
            columnsMicroArea: [{headerName: "Nome zona", field: "name", width: 240}],
            columnsMicroAreaGroup: [{headerName: "Nome gruppo", field: "name", width: 240}, {
                headerName: "Zone", field: "micro_areas", width: 500,
                renderCell: (params) => params.getValue(params.id, "micro_areas").map(item => {
                    return item.name + " - ";
                })
            }],
            rows: [],
            rowsMicroArea: [],
            rowsMicroAreaGroup: [],
            columnsSociety: [
                {headerName: "Nome ente", field: "name", width: 180},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {
                    headerName: " ",
                    width: 220,
                    field: 'id',
                    renderCell: (params) => {
                        if (this.state.ticket.society.id === params.id)
                            return "-";
                        let find = this.state.listShare.find(item => item.id === params.id);
                        if (find)
                            return <Button onClick={() => this.setTicketShareSociety(params.id)} type="button"
                                           color="danger">Rimuovi</Button>
                        return <Button onClick={() => this.setTicketShareSociety(params.id)} type="button"
                                       color="success">Assegna</Button>
                    }
                }
            ],
            rowsSociety: [],
            columnsPackage: [
                {headerName: "Nome pacchetto", field: "name", width: 240},
                {
                    headerName: "Tipologia",
                    field: "type",
                    width: 180,
                    renderCell: (params) => this.convertType(params.value)
                },
                {
                    headerName: "Prezzo",
                    field: "price",
                    width: 180,
                    renderCell: (params) => "€ " + params.value.toFixed(2)
                },
                {
                    headerName: "Disponibilità rimanente",
                    field: "availability",
                    width: 220,
                    renderCell: (params) => {
                        if (params.value !== null)
                            return params.value;
                        return "∞";
                    }
                },
                {
                    headerName: " ",
                    width: 220,
                    field: 'id',
                    renderCell: (params) => {
                        console.log("params")
                        console.log(params)
                        let find = this.state.listPackages.find(item => item.id === params.id);
                        if (find)
                            return <Button onClick={() => this.setTicketPackage(params.row)} type="button"
                                           color="danger">Rimuovi</Button>
                        return <Button onClick={() => this.setTicketPackage(params.row)} type="button"
                                       color="success">Aggiungi</Button>
                    }
                }
            ],
            rowsPackage: [],
            listShare: [],
            listPackages: [],
            form_edit: {
                id_area: null,
                id_micro_area: null,
                id_ticket: null,
                name: null,
                price: null,
                date_start_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                date_end_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                type: "DAILY",
                id_residence_type: null,
                maximum_use: null,
                maximum_ticket_purchasable: null,
                send_regulation: false,
                is_date_use_required: false,
                is_hidden: null,
                availability: null,
                is_disabled_score_capture: null,
                is_fipsas_disabled: null,
                service_fee: null,
                duration: null,
                shop_packages: [],
                minimum_age: null,
                languages: null,
                is_free_cancellation: null,
                invoice_template: null,
            },
            listServiceTags: [],
            service_tags: [],
            date_start: new Date(),
            date_end: new Date(),
            date_current: new Date(),
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorSociety: {loading: true, countRows: 0, limit: 50, page: 1},
            paginatorPackage: {loading: true, countRows: 0, limit: 50, page: 1},
            area: null,
            micro_area: null,
            micro_area_group: null,
            ticket: null,
            residenceTypes: [],
            showTab: "area",
            showAreas: false,
            modal: false,
            modalExtraData: false,
            modalPackages: false,
            arr_extra_data: [],
            extraDataSelect: null,
            fieldSelect: null,
            typeTicket: "DAILY"
        };
        this.quill = null;
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            let res = await infoTicket(this.props.match.params.id);
            if (typeof res.error !== "undefined") {
                global.notify("Errore: " + res.error, "danger");
                return;
            }
            let ticket = res.ticket;
            let form_edit = {
                name: ticket.name,
                price: ticket.price,
                description: ticket.description,
                last_price: ticket.last_price,
                date_start_validity: ticket.date_start_validity,
                date_end_validity: ticket.date_end_validity,
                type: ticket.type,
                id_residence_type: (ticket.ticket_residence_type !== null) ? ticket.ticket_residence_type.id : null,
                maximum_use: (ticket.maximum_usage !== null) ? ticket.maximum_usage : null,
                maximum_ticket_purchasable: (ticket.maximum_ticket_purchasable !== null) ? ticket.maximum_ticket_purchasable : null,
                availability: (ticket.availability !== null) ? ticket.availability : null,
                send_regulation: ticket.send_regulation,
                is_date_use_required: ticket.is_date_use_required,
                is_disabled_score_capture: ticket.is_disabled_score_capture,
                is_hidden: ticket.is_hidden,
                service_fee: ticket.service_fee,
                shop_packages: ticket.shop_packages,
                is_fipsas_disabled: ticket.is_fipsas_disabled,
                minimum_age: ticket.minimum_age,
                languages: ticket.languages,
                is_free_cancellation: ticket.is_free_cancellation,
                invoice_template: ticket.invoice_template,
                duration:  this.convertTime(ticket.duration_time)
            }
            console.log(Math.floor(Number(ticket.duration_time) / 3600)+ ":" +  Math.floor(Number(ticket.duration_time)  % 3600 / 60))
            let service_tags = [];
            for (let i in ticket.service_tags)
                service_tags.push(ticket.service_tags[i].id);
            this.setState({
                area: ticket.area,
                micro_area: typeof ticket.micro_area !== "undefined" ? ticket.micro_area : null,
                micro_area_group: typeof ticket.micro_area_group !== "undefined" ? ticket.micro_area_group : null,
                form_edit: form_edit,
                ticket: ticket,
                service_tags: service_tags,
                typeTicket: ticket.type,
                arr_extra_data: ticket.extra_data,
                listPackages: ticket.shop_packages,
                date_start: moment(ticket.date_start_validity, "DD/MM/YYYY").toDate(),
                date_end: moment(ticket.date_end_validity, "DD/MM/YYYY").toDate()
            })
        }
        let residenceTypes = await ticketResidenceList();
        if (typeof residenceTypes.error === "undefined")
            this.setState({residenceTypes: residenceTypes.list})

        this.showArea("area");
        getListServiceTag().then(result => {
            if (!result) {
                this.setState({listServiceTags: []});
            } else {
                this.setState({listServiceTags: result.list});
            }
        });
    }

     convertTime(sec) {
         const d = Number(sec);
         const h = Math.floor(d / 3600);
         const m = Math.floor((d % 3600) / 60);
         const s = Math.floor((d % 3600) % 60);
         const hDisplay = h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00';
         const mDisplay = m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
         return `${hDisplay}:${mDisplay}`;
    }

    async handleValidSubmit(event, values, edit = false) {
        console.log("values")
        console.log(values)
        if (this.state.area === null) {
            global.notify("Devi selezionare l'area a cui associare il ticket", "danger");
            return;
        }
        values.id_area = this.state.area.id;
        if (this.state.form_edit.description !== null && this.state.form_edit.description.trim() !== "" && this.state.form_edit.description !== "<p><br></p>")
            values.description = this.state.form_edit.description;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;

        if (this.state.micro_area_group !== null)
            values.id_micro_area_group = this.state.micro_area_group.id;
        if (this.state.typeTicket === "HOURLY") {
            let val = this.state.form_edit.duration.split(":");
            if (!(val[0] === "00" && val[1] === "00"))
                values.duration = parseInt(val[0]) * 60 * 60 + parseInt(val[1]) * 60;
        }
        values.service_tags = JSON.stringify(this.state.service_tags);
        values.id = this.state.ticket.id;
        if (values.last_price === null)
            delete values.last_price;
        let formResult = await editTicket(values);

        if (typeof formResult.ticket !== 'undefined') {
            global.notify("Ticket modificato con successo", "success");
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");

            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    convertType = (type) => {
        switch (type) {
            case "PREMIUM":
                return "Premium";
            case "INSURANCE":
                return "Assicurazione";
            case "RECOMMEND":
                return "Raccomandati";
            case "BOUGHT_TOGETHER":
                return "Comprato insieme";
            case "PRODUCT":
                return "Prodotto";
        }
    }

    actionButton = (params) => {
        this.setState({form_edit: params.data, flag_edit: true});
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start_validity = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end_validity = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    showArea = (state) => {
        if (state !== "area" && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === "micro_area_group" && (typeof this.state.area.micro_areas_group === "undefined" || Object.keys(this.state.area.micro_areas_group).length === 0)) {
            global.notify("Non ci sono gruppi presenti per quest'area", "warning");
            return;
        }
        if (state === "micro_area" && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono zone presenti per quest'area", "warning");
            return;
        }

        this.setState({showTab: state});
        if (state === "area") {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTab) {
            case "area":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : [],
                    rowsMicroAreaGroup: typeof row.micro_areas_group !== "undefined" ? row.micro_areas_group : []
                }, () => this.showArea("micro_area"));
                break;
            case "micro_area_group":
                if (this.state.micro_area !== null)
                    global.notify("Non puoi impostare una zona specifica con il gruppo di zone", "warning");
                this.setState({micro_area_group: row, micro_area: null});
                break;
            case "micro_area":
                if (this.state.micro_area_group !== null)
                    global.notify("Non puoi impostare una zona specifica con il gruppo di zone", "warning");
                this.setState({micro_area: row, micro_area_group: null});
                break;
        }
    }

    disableTicket = async () => {
        let ticket = this.state.ticket;

        let formResult = await disableTicket({id: ticket.id, is_disabled: ticket.is_disabled ? "false" : "true"});
        if (typeof formResult.ticket !== 'undefined') {
            global.notify("Ticket " + (ticket.is_disabled ? "riattivato" : "disabilitato") + " con successo", "success");
            this.setState({ticket: formResult.ticket});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico ", "danger");
        }
    }

    deleteTicket = async () => {
        let ticket = this.state.ticket;
        let formResult = await deleteTicket(ticket.id);
        if (typeof formResult.error === 'undefined') {
            global.notify("Ticket eliminato con successo", "success");
            window.location.reload();
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    showModal = () => {
        ticketShareListSociety(this.state.ticket.id).then(result => {
            if (!result) {
                this.setState({listShare: []});
            } else {
                this.setState({listShare: result.list});
            }
            this.setState({modal: true});
            this.loadListSocieties(this.state.paginatorSociety.page - 1);
        });
    }

    showModalPackages = () => {
        console.log("show modal")
        this.setState({modalPackages: true});
        this.loadListPackages(0);
    }

    loadListSocieties = (page) => {
        let paginator = this.state.paginatorSociety;
        paginator.loadingSociety = true;
        this.setState({paginatorSociety: paginator});

        getSocieties(page + 1).then(result => {
            paginator.loadingSociety = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rowsSociety: [], paginatorSociety: paginator});
            } else {
                paginator.page = result.paginator.page;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsSociety: result.list, paginatorSociety: paginator});
            }
        });
    }

    loadListPackages = (page) => {
        console.log("load list")
        let paginator = this.state.paginatorPackage;
        paginator.loading = true;
        this.setState({paginatorPackage: paginator});

        listShopPackage(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rowsPackage: [], paginatorPackage: paginator});
            } else {
                paginator.page = result.paginator.page;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsPackage: result.list, paginatorPackage: paginator});
            }
        });
    }

    handlePageChangeSociety = (params) => {
        this.loadListSocieties(params.page);
    };

    handlePageChangePackage = (params) => {
        this.loadListPackages(params.page);
    };

    renderModal = () => {
        return <Modal isOpen={this.state.modal} backdrop="static" keyboard={false} size="lg">
            <ModalHeader>Associa ticket agli enti</ModalHeader>
            <ModalBody>
                <div style={{height: 400, width: '100%'}}>
                    <DataGrid
                        rows={this.state.rowsSociety}
                        columns={this.state.columnsSociety}
                        pagination
                        pageSize={this.state.paginatorSociety.limit}
                        rowsPerPageOptions={[this.state.paginatorSociety.limit]}
                        rowCount={this.state.paginatorSociety.countRows}
                        paginationMode="server"
                        onPageChange={this.handlePageChangeSociety}
                        loading={this.state.paginatorSociety.loadingSociety}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => this.setState({modal: false})}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    deleteExtraData = (id) => {
        ticketExtraDataDelete({id: this.state.ticket.id, id_extra_data: id}).then((result) => {
            if (typeof result.ticket !== 'undefined') {
                global.notify("Campo eliminato", "success");
                this.setState({arr_extra_data: result.ticket.extra_data});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    addShopPackage = (id) => {
        addShopPackageTicket(this.state.ticket.id, id).then((result) => {
            if (typeof result.ticket !== 'undefined') {
                global.notify("Pacchetto aggiunto", "success");
                this.setState({ticket: result.ticket});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    deleteShopPackage = (id) => {
        removeShopPackageTicket(this.state.ticket.id, id).then((result) => {
            if (typeof result.ticket !== 'undefined') {
                global.notify("Pacchetto rimosso", "success");
                this.setState({ticket: result.ticket});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    renderExtraData = () => {
        let list;
        if (Object.keys(this.state.arr_extra_data).length === 0)
            list = <ListGroupItem>Non ci sono campi aggiuntivi impostati</ListGroupItem>
        else {
            list = this.state.arr_extra_data.map((item, key) => {
                return <ListGroupItem style={{fontSize: 15}}>
                    <Badge color="light" pill>Nome: <b>{item.name}</b></Badge>
                    <Badge color="light" pill>Tipologia: <b>{item.type === "STRING" ? "Testo" : "Data"}</b></Badge>
                    <div style={{float: "right"}}>
                        <Button color={"danger"} size="sm"
                                onClick={() => this.deleteExtraData(item.id)}>Elimina</Button>
                    </div>
                </ListGroupItem>
            });
        }

        return <div className={"row"}>
            <div className={"col-md-12"}>
                <hr/>
                <h5>Campi aggiuntivi</h5>
                <div style={{marginTop: "auto"}}>
                    <Button color="info" size={"sm"} onClick={() => this.setState({modalExtraData: true})}>Aggiungi
                        campi</Button>
                </div>
                <br/>
                <ListGroup>{list}</ListGroup>
            </div>
        </div>
    }

    renderPackages = () => {
        let list;
        if (Object.keys(this.state.listPackages).length === 0)
            list = <ListGroupItem>Non ci sono pacchetti aggiuntivi impostati per questo ticket</ListGroupItem>
        else {
            list = this.state.listPackages.map((item, key) => {
                return <ListGroupItem style={{fontSize: 15}}>
                    <Badge color="light" pill>Nome: <b>{item.name}</b></Badge>
                    <Badge color="light" pill>Tipologia: <b>{this.convertType(item.type)}</b></Badge>
                    <Badge color="light" pill>Prezzo: <b>{item.price}</b></Badge>
                    <div style={{float: "right"}}>
                        <Button color={"danger"} size="sm"
                                onClick={() => this.setTicketPackage(item)}>Elimina</Button>
                    </div>
                </ListGroupItem>
            });
        }

        return <div className={"row"}>
            <div className={"col-md-12"}>
                <hr/>
                <h5>Pacchetti aggiuntivi</h5>
                <div style={{marginTop: "auto"}}>
                    <Button color="primary" size={"sm"} onClick={() => this.showModalPackages()}>Aggiungi
                        pacchetti</Button>
                </div>
                <br/>
                <ListGroup>{list}</ListGroup>
            </div>
        </div>
    }

    setTicketShareSociety = (society) => {
        ticketShareSociety({id_society: society, id_ticket: this.state.ticket.id}).then(() => {
            this.showModal();
        });
    }

    setTicketPackage = (packages) => {
        let res = [];
        let deleted = false;
        let list = this.state.listPackages;
        for (let i in list) {
            if (list[i].id === packages.id) {
                deleted = true;
                this.deleteShopPackage(packages.id);
            } else
                res.push(list[i]);
        }
        if (!deleted) {
            res.push(packages);
            this.addShopPackage(packages.id);
        }
        this.setState({listPackages: res});
    }

    handleSubmitExtraData(event, values) {
        let arr_values = [];
        if (values.type === "SELECT") {
            if (this.state.extraDataSelect !== null && Object.keys(this.state.extraDataSelect).length > 0) {
                arr_values = this.state.extraDataSelect;
            } else {
                global.notify("Errore: devi impostare dei campi per la scelta", "danger");
                return;
            }
        }

        ticketExtraDataAdd({
            id: this.state.ticket.id,
            name: values.name,
            type: values.type,
            link: values.link,
            is_required: values.is_required,
            values: JSON.stringify(arr_values)
        }).then((result) => {
            if (typeof result.ticket !== 'undefined') {
                global.notify("Campo aggiunto", "success");
                this.setState({arr_extra_data: result.ticket.extra_data, modalExtraData: false, extraDataSelect: null});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    deleteTag = async (id) => {
        let tags = this.state.service_tags;
        const index = tags.indexOf(id);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({service_tags: tags});
        }
    }

    setTag = async (id) => {
        let tags = this.state.service_tags;
        tags.push(id);
        this.setState({service_tags: tags});
    }

    listServiceTags = () => {
        let list;
        if (Object.keys(this.state.listServiceTags).length === 0)
            list = <ListGroupItem>Non ci sono Tag da impostare</ListGroupItem>
        else {
            list = this.state.listServiceTags.map((item, key) => {
                return <Chip className={"mb-2 mr-1"}
                             label={<strong>{item.name}</strong>}
                             onClick={() => this.state.service_tags.includes(item.id) ? this.deleteTag(item.id) : this.setTag(item.id)}
                             icon={this.state.service_tags.includes(item.id) ? <DoneIcon/> : null}
                             variant={this.state.service_tags.includes(item.id) ? "outlined" : null}
                />
            });
        }
        return <div>{list}</div>
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.ticket.id);
            uploadImageTicket(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let ticket = this.state.ticket;
                    ticket.image = result.ticket.image;
                    this.setState({ticket: ticket});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    renderModalExtraData = () => {
        let extraSelect = "Non ci sono campi impostati";
        let extraDataSelect = this.state.extraDataSelect;
        if (extraDataSelect !== null && Object.keys(extraDataSelect).length > 0) {
            extraSelect = [];
            for (let i in extraDataSelect)
                extraSelect.push(<p><b>{extraDataSelect[i]}</b> <Button color="danger" size={"sm"}
                                                                        onClick={() => {
                                                                            let extraDataSelect = this.state.extraDataSelect;
                                                                            delete extraDataSelect[i];
                                                                            this.setState({extraDataSelect: extraDataSelect});
                                                                        }}>Elimina</Button></p>);
        }

        return <Modal isOpen={this.state.modalExtraData} backdrop="static" keyboard={false}>
            <AvForm onValidSubmit={(e, v,) => {
                this.handleSubmitExtraData(e, v)
            }}>
                <ModalHeader>Aggiungi campi extra per il ticket</ModalHeader>
                <ModalBody>
                    <div className="form-horizontal">
                        <div className={"row"}>
                            <div className="form-group col-md-12">
                                <AvField name="name" label="Nome del campo"
                                         className="form-control" placeholder="Inserisci nome del campo"
                                         type="text" required/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField name="type" label="Tipologia del campo" onChange={(e) => {
                                    if (e.target.value === "SELECT")
                                        this.setState({extraDataSelect: []});
                                    else
                                        this.setState({extraDataSelect: null});
                                }}
                                         className="form-control" placeholder="Seleziona tipologia del campo"
                                         type="select" required>
                                    <option value={"STRING"}>Testo</option>
                                    <option value={"DATE"}>Data</option>
                                    <option value={"SELECT"}>Scelta</option>
                                    <option value={"CHECKBOX"}>Flag</option>
                                </AvField>
                            </div>
                            {this.state.extraDataSelect !== null ?
                                <div className={"col-md-12"} style={{backgroundColor: "#E0E0E0"}}>
                                    <div className={"row pt-2"}>
                                        <div className="input-group col-md-12 mb-2">
                                            <input name="field_select" label="Valore campo" className="form-control"
                                                   placeholder="Valore campo" value={this.state.fieldSelect}
                                                   onChange={(e) => this.setState({fieldSelect: e.target.value})}
                                                   type="text"/>
                                            <Button color="success"
                                                    onClick={() => {
                                                        let extraDataSelect = this.state.extraDataSelect;
                                                        extraDataSelect.push(this.state.fieldSelect);
                                                        this.setState({
                                                            extraDataSelect: extraDataSelect,
                                                            fieldSelect: ""
                                                        });
                                                    }}>Aggiungi</Button>
                                        </div>
                                        <div className="col-md-12">
                                            {extraSelect}
                                        </div>
                                    </div>
                                </div> : <></>}
                            <div className="form-group col-md-12">
                                <AvField name="link" label="Link" className="form-control" placeholder="Inserisci link"
                                         type="text"/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField type="checkbox" className="form-check-input"
                                         id="is_required" name="is_required" checked={false}
                                         label="Il campo è obbligatorio"/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="success">Aggiungi</Button>
                    <Button color="secondary" onClick={() => this.setState({modalExtraData: false})}>Chiudi</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    }

    renderModalPackages = () => {
        return <Modal isOpen={this.state.modalPackages} backdrop="static" keyboard={false} size="lg">
            <ModalHeader>Aggiungi pacchetti aggiuntivi per il ticket</ModalHeader>
            <ModalBody>
                <div style={{height: 400, width: '100%'}}>
                    <DataGrid
                        rows={this.state.rowsPackage}
                        columns={this.state.columnsPackage}
                        pagination
                        pageSize={this.state.paginatorPackage.limit}
                        rowsPerPageOptions={[this.state.paginatorPackage.limit]}
                        rowCount={this.state.paginatorPackage.countRows}
                        paginationMode="server"
                        onPageChange={this.handlePageChangePackage}
                        loading={this.state.paginatorPackage.loading}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => this.setState({modalPackages: false})}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        const editor = createEditor();
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem={"Modifica ticket"}/>
                        <Card>
                            <CardBody>
                                {this.state.showAreas ? <>
                                    <div className="text-center mb-4">
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => this.showArea("area")}
                                                color={(this.state.showTab === "area") ? "light" : "primary"}>Area</Button>
                                            <Button
                                                onClick={() => this.showArea("micro_area_group")}
                                                color={(this.state.showTab === "micro_area_group") ? "light" : "primary"}>Gruppo
                                                zone</Button>
                                            <Button
                                                onClick={() => this.showArea("micro_area")}
                                                color={(this.state.showTab === "micro_area") ? "light" : "primary"}>Zona</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div style={{height: '40vh', width: '100%'}}
                                         hidden={(this.state.showTab !== "area")}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                            onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                        />
                                    </div>

                                    <div style={{height: '40vh', width: '100%'}}
                                         hidden={(this.state.showTab !== "micro_area_group")}>
                                        <DataGrid
                                            rows={this.state.rowsMicroAreaGroup}
                                            columns={this.state.columnsMicroAreaGroup}
                                            pageSize={Object.keys(this.state.rowsMicroAreaGroup).length}
                                            rowsPerPageOptions={[Object.keys(this.state.rowsMicroAreaGroup).length]}
                                            rowCount={Object.keys(this.state.rowsMicroAreaGroup).length}
                                            onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                        />
                                    </div>
                                    <div style={{height: '40vh', width: '100%'}}
                                         hidden={(this.state.showTab !== "micro_area")}>
                                        <DataGrid
                                            rows={this.state.rowsMicroArea}
                                            columns={this.state.columnsMicroArea}
                                            pageSize={Object.keys(this.state.rowsMicroArea).length}
                                            rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                            rowCount={Object.keys(this.state.rowsMicroArea).length}
                                            onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                        />
                                    </div>
                                </> : <div className="text-center">
                                    <Button type="button" color="primary"
                                            onClick={() => this.setState({showAreas: true})}>Modifica area</Button>
                                </div>}
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area_group !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Gruppo zone</CardTitle>
                                                <CardText>Nome
                                                    gruppo: {this.state.micro_area_group.name}<br/>Zone: {this.state.micro_area_group.micro_areas.map(item => {
                                                        return item.name + " - ";
                                                    })}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Zona</CardTitle>
                                                <CardText>Nome zona: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                    this.handleValidSubmit(e, v)
                                }}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="name" label="Nome del ticket" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci nome"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="type" label="Tipo" value={this.state.form_edit.type}
                                                     className="form-control" placeholder="Inserisci tipo"
                                                     type="select" required                                                      onChange={(value) => (this.setState({typeTicket: value.target.value}))}
                                            >
                                                <option value={"HOURLY"}>Orario</option>
                                                <option disabled>Tipologie a giorni</option>
                                                <option value={"DAILY"} selected={true}>Giornaliero</option>
                                                <option value={"DAILY"}>Giornaliero</option>
                                                <option value={"TWODAYS"}>Bigiornaliero</option>
                                                <option value={"THREEDAYS"}>Tre giorni</option>
                                                <option value={"FOURDAYS"}>Quattro giorni</option>
                                                <option value={"WEEKDAYS"}>Cinque giorni</option>
                                                <option value={"WEEKLY"}>Settimanale</option>
                                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                                <option value={"MONTHLY"}>Mensile</option>
                                                <option value={"TRIMESTER"}>Trimestrale</option>
                                                <option value={"SEASONAL"}>Stagionale</option>
                                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                                <option value={"YEARLY"}>Annuale</option>
                                                <option value={"BIANNUAL"}>Biennale</option>
                                                <option value={"TRIENNIAL"}>Triennale</option>
                                            </AvField>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="price" label="Prezzo finale" value={this.state.form_edit.price}
                                                     className="form-control" placeholder="Inserisci prezzo"
                                                     type="text" required/>
                                            <small>il prezzo che verrà addebitato sulla carta</small>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="last_price" label="Prezzo pieno"
                                                     value={this.state.form_edit.last_price}
                                                     className="form-control" placeholder="Inserisci prezzo pieno"
                                                     type="text"/>
                                            <small>Serve per indicare lo sconto, è il prezzo prima dello sconto (non
                                                obbligatorio)</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="service_fee" label="Commissione di servizio"
                                                     value={this.state.form_edit.service_fee}
                                                     className="form-control" placeholder="Commissione" type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_start_validity" hidden
                                                     label="Data inizio"
                                                     value={this.state.form_edit.date_start_validity}
                                                     className="form-control"
                                                     placeholder="Inserisci data" type="text"
                                                     required/>
                                            <DatePicker selected={this.state.date_start}
                                                        startDate={this.state.date_current} className="form-control"
                                                        onChange={(date) => this.setDate(date, "start")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_end_validity" hidden label="Data fine"
                                                     value={this.state.form_edit.date_end_validity}
                                                     className="form-control"
                                                     placeholder="Inserisci data" type="text"
                                                     required/>
                                            <DatePicker selected={this.state.date_end}
                                                        minDate={this.state.date_start} className="form-control"
                                                        onChange={(date) => this.setDate(date, "end")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="id_residence_type" label="Categoria del ticket"
                                                     value={this.state.form_edit.id_residence_type}
                                                     className="form-control" placeholder="Categoria del ticket"
                                                     type="select" required>
                                                {this.state.residenceTypes.map((data, i) => {
                                                    return <option value={data.id}>{data.name}</option>
                                                })}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="maximum_use" label="Numero massimo di utilizzi del ticket"
                                                     value={this.state.form_edit.maximum_use}
                                                     className="form-control" placeholder="Inserisci numero"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="maximum_ticket_purchasable"
                                                     value={this.state.form_edit.maximum_ticket_purchasable}
                                                     label="Numero massimo di ticket aquistabili"
                                                     className="form-control"
                                                     placeholder="Numero massimo di ticket aquistabili"
                                                     type="number"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="availability" label="Quantità dei ticket disponibili"
                                                     value={this.state.form_edit.availability}
                                                     className="form-control" placeholder="Inserisci la disponibilità"
                                                     type="text"/>
                                            <small>(se il campo è vuoto, la disponibilità sarà illimitata)</small>
                                        </div>
                                        {this.state.typeTicket === "HOURLY" ?
                                            <div className="form-group col-md-4">
                                                <label htmlFor={"duration"}>Durata del ticket</label>
                                                <InputMask name={"duration"} className="form-control"
                                                           mask={[/[0-9]/, /[0-3]/, ':', /[0-5]/, /[0-9]/]}
                                                           value={this.state.form_edit.duration}
                                                           onChange={(e) => {
                                                               let form_edit = this.state.form_edit;
                                                               let value = e.target.value;
                                                               value = value.replace("_", "0")
                                                               let hours = value.split(":");
                                                               if (parseInt(hours[0]) >= 24)
                                                                   value = "23:" + hours[1];
                                                               form_edit.duration = value;
                                                               this.setState({form_edit: form_edit});
                                                           }}
                                                           placeholder={"Chiusura"}/>
                                            </div> : <></>}
                                        <div className="col-md-12">
                                            <hr/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_date_use_required" name="is_date_use_required"
                                                     checked={this.state.form_edit.is_date_use_required}
                                                     label="Date d'utilizzo obbligatorie?"/>
                                            <small>(obbligatorio se viene il ticket è associato alle zone a numero
                                                chiuso)</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="send_regulation" name="send_regulation"
                                                     checked={this.state.form_edit.send_regulation}
                                                     label="Invio del regolamento via email?"/>
                                            <small>(verrà inviato il regolamento via email all'utente dopo l'acquisto
                                                del ticket)</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_hidden" name="is_hidden"
                                                     checked={this.state.form_edit.is_hidden}
                                                     label="Ticket nascosto in APP?"/>
                                            <small>(il ticket verrà nascosto in applicazione/sito web e si può vedere
                                                solo nel gestionale)</small>
                                        </div>
                                        {this.state.area !== null && this.state.area.category.type === "TYPE_FISHING" ?
                                            <div className="form-group col-md-4">
                                                <AvField type="checkbox" className="form-check-input"
                                                         checked={this.state.form_edit.is_disabled_score_capture}
                                                         value={this.state.form_edit.is_disabled_score_capture}
                                                         id="is_disabled_score_capture" name="is_disabled_score_capture"
                                                         label="Ticket disabilitato per il segnacatture?"/>
                                                <small>(il ticket verrà disabilitato per il segnacatture)</small>
                                            </div> : <></>}

                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     checked={this.state.form_edit.is_fipsas_disabled}
                                                     value={this.state.form_edit.is_fipsas_disabled}
                                                     id="is_fipsas_disabled" name="is_fipsas_disabled"
                                                     label="Ticket disabilitato per il controllo fipsas?"/>
                                            <small>(verrà disabilitato il controllo dei tesserini fipsas per questo
                                                ticket)</small>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="form-group col-md-4">
                                            <AvField name="minimum_age" label="Età minima"
                                                     className="form-control" placeholder="Età minima"
                                                     value={this.state.form_edit.minimum_age} type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="languages" label="Lingue disponibili"
                                                     className="form-control" placeholder="Lingue disponibili"
                                                     value={this.state.form_edit.languages} type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     checked={this.state.form_edit.is_free_cancellation}
                                                     id="is_free_cancellation" name="is_free_cancellation"
                                                     value={this.state.form_edit.is_free_cancellation}
                                                     label="Cancellazione gratuita entro 24 ore"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="select" name="invoice_template"
                                                     label="Template del ticket PDF" value={this.state.form_edit.invoice_template}>
                                                <option value={""}>Standard</option>
                                                <option value={"INVOICE_SPAIN_DAILY"}>Spagna - Permesso giornaliero
                                                </option>
                                                <option value={"INVOICE_SPAIN_WEEK"}>Spagna - Permesso settimanale
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_ORANGE"}>Spagna - Permesso annuale
                                                    ARANCIONE
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_BLUE"}>Spagna - Permesso annuale
                                                    AZZURRO
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_GREEN"}>Spagna - Permesso annuale
                                                    VERDE
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_E"}>Spagna - Permesso annuale E
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_F"}>Spagna - Permesso annuale F
                                                </option>
                                                <option value={"INVOICE_SPAIN_ANNUAL_G"}>Spagna - Permesso annuale G
                                                </option>
                                            </AvField>
                                        </div>
                                    </div>
                                    <hr/>
                                    <p><b>Descrizione ticket</b></p>
                                    <ReactQuill theme="snow"
                                                value={this.state.form_edit.description}
                                                ref={(ref) => this.quill = ref}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [
                                                                {header: [1, 2, 3, 4, 5, 6, false]},
                                                            ],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'line'],
                                                            [
                                                                {list: 'ordered'},
                                                                {list: 'bullet'},
                                                                {align: []},
                                                            ],
                                                            [{color: []}],
                                                            ['link'],
                                                            ['clean'],
                                                        ],
                                                    },
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                                formats={[
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link',
                                                    'color',
                                                    'formats/style', 'style', 'line', 'align', 'clean', 'hr'
                                                ]}
                                                onChange={(value) => {
                                                    let form_edit = this.state.form_edit;
                                                    form_edit.description = value;
                                                    this.setState({form_edit: form_edit});
                                                }}/>
                                    <hr/>
                                    <h5>Lista servizi</h5>
                                    {this.listServiceTags()}
                                    {this.renderExtraData()}
                                    {this.renderPackages()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="warning">Modifica</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        {this.state.ticket !== null ? <Card className={"mt-16"}>
                            <CardBody align={"center"}>
                                <ButtonGroup>
                                    <Button type="button" color={"primary"}
                                            onClick={() => this.showModal()}>Associa ticket agli
                                        enti</Button>
                                    <Button type="button"
                                            onClick={() => window.location.href = '/ticket/share/' + this.state.ticket.id}
                                            color="info">Assegna ticket ad cliente</Button>
                                    <Button type="button" color={this.state.ticket.is_disabled ? "success" : "warning"}
                                            onClick={() => this.disableTicket()}>
                                        {this.state.ticket.is_disabled ? "Riattiva ticket" : "Disabilita ticket"}</Button>
                                    <Button type="button" color="danger" onClick={() => this.deleteTicket()}>Elimina
                                        ticket</Button>
                                </ButtonGroup>
                            </CardBody>
                        </Card> : <></>}
                        <Card>
                            <CardBody>
                                <h5>Caricamento immagini</h5>
                                <hr/>
                                <div className="row">
                                    <Card className={'col-md-12'}>
                                        <CardBody>
                                            <h6>Scegli immagine</h6>
                                            <div align={"center"}>
                                                <img
                                                    src={(this.state.ticket !== null && this.state.ticket.image !== null) ? global.baseUrlImg + '/' + this.state.ticket.image : ""}
                                                    style={{width: "100%"}}/>
                                            </div>
                                            <hr/>
                                            <div align={"center"}>
                                                <input type="file" name="image" onChange={this.onImageChange}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModal()}
                    {this.renderModalExtraData()}
                    {this.renderModalPackages()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {addTicket, getListAreas})(TicketAddPage));
