import React, {Component} from "react";
import {Card, CardBody, Container, Button, CardTitle} from "reactstrap";
import GoogleMapReact from 'google-map-react';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getCategories, getMapAreas, getListRegion, addZoneShowcase} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

class ZoneShowcasePage extends Component {
    static defaultProps = {
        center: {
            lat: 42.45,
            lng: 12.96
        },
        zoom: 5.5
    };

    constructor(props) {
        super(props);
        this.state = {
            form_edit: {
                id_category: null,
                name: null,
                lat: null,
                lon: null,
                region: null,
                province: null,
                address: null,
                number: null,
                city: null,
                cap: null,
                description: null,
                email: null,
                email_cc: null,
            },
            categories: [],
            lat: null,
            lon: null,
            polygons: [],
            map: null,
            maps: null,
            id_category: 0,
            category: null,
            markers: [],
            markersObj: [],
            polygonsObj: [],
            regionList: []
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values) {
        values.lat = this.state.lat;
        values.lon = this.state.lon;

        let formResult = await addZoneShowcase(values);
        if (typeof formResult.error === 'undefined') {
            global.notify("Area aggiunta con successo", "success");
            window.location.href = "/showcase/edit/" + formResult.area.id;
        } else
            global.notify(formResult.error, "danger");
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.data});
    }

    async componentDidMount() {
        let categories = await getCategories();
        this.setState({categories: categories});
        if (Object.keys(categories).length > 0)
            this.setState({category: categories[0]});
        this.loadRegions();
    }

    loadRegions = () => {
        getListRegion().then(result => {
            if (!result) {
                this.setState({regionList: []});
            } else {
                let regions = [];
                result.list.map((value, i) => {
                    if (typeof regions[value.country] === "undefined")
                        regions[value.country] = [];
                    regions[value.country].push(value.region);
                })
                this.setState({regionList: regions});
            }
        })
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.handleApiLoaded(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let bounds = map.getBounds();
        let category = null;
        if(this.state.category !== null)
            category = this.state.category.id;
        getMapAreas(category, bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (typeof this.state.markers.find(m => {
                    return m.id === area.id
                }) !== "undefined")
                    continue;

                arr_markers.push({id: area.id});
                let marker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });

                let markers = this.state.markersObj;
                markers.push(marker);
                this.setState({markersObj: markers});

                if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                    let polygon = [];
                    for (let i in area.area_cords)
                        polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                    let polygonObj = new maps.Polygon({
                        paths: polygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });
                    polygonObj.setMap(map);
                    let polygons = this.state.polygonsObj;
                    polygons.push(polygonObj);
                    this.setState({polygonsObj: polygons});
                }
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    renderMap() {
        let mapObj = this.state.map;
        let mapsObj = this.state.maps;

        let markerDragged = (e) => {
            this.setState({lat: e.latLng.lat(), lon: e.latLng.lng()})
        }


        let setMarker = () => {
            let marker = new mapsObj.Marker({
                map: mapObj,
                animation: mapsObj.Animation.DROP,
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    scaledSize: new mapsObj.Size(42, 42)
                },
                position: new mapsObj.LatLng({lat: mapObj.center.lat(), lng: mapObj.center.lng()}),
                draggable: true
            });
            mapsObj.event.addListener(marker, 'dragend', markerDragged);
            this.setState({lat: mapObj.center.lat(), lon: mapObj.center.lng()})
        }

        return <Card body inverse style={{backgroundColor: '#eeeeee', borderColor: '#eeeeee'}}>
            <div className="text-center mb-4">
                <div className="btn-group">
                    <Button type="button" color="success"
                            disabled={this.state.lat !== null && this.state.lon !== null} onClick={setMarker}>Aggiungi
                        Marker</Button>
                </div>
            </div>

            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAi605IDjNIDTzD4KhD3gaYrlPbukWuM9c"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                />
            </div>
        </Card>
    }

    listCategories() {
        if (Object.keys(this.state.categories).length === 0)
            return;
        return this.state.categories.map(function (category, i) {
            return <option value={category.id} key={i}>{category.name}</option>
        });
    }

    renderListRegion = (regionSelected = null) => {
        let ret = [];
        for (let country in this.state.regionList) {
            ret.push(<option disabled>{country}</option>)
            for (let region in this.state.regionList[country]) {
                let selected = null;
                if (regionSelected !== null && this.state.regionList[country][region] === regionSelected)
                    selected = "selected";
                ret.push(<option
                    value={this.state.regionList[country][region]}
                    selected={selected}>{this.state.regionList[country][region]}</option>)
            }
        }
        return ret;
    }

    changeCategory = (id_category) => {
        let category = null;
        for (let i in this.state.categories) {
            if (this.state.categories[i].id === parseInt(id_category))
                category = this.state.categories[i];
        }
        for (let i in this.state.markersObj)
            this.state.markersObj[i].setMap(null);
        for (let i in this.state.polygonsObj)
            this.state.polygonsObj[i].setMap(null);

        this.setState({category: category, markers: [], markersObj: [], polygonsObj: []}, () => {
                this.handleApiLoaded(this.state.map, this.state.maps)
            }
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Zone vetrina"/>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Aggiunta zone vetrina</CardTitle>

                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">

                                        <div className="form-group col-md-12">
                                            <AvField type="select" name="id_category" label="Categoria"
                                                     value={Object.keys(this.state.categories).length > 0 ? this.state.categories[0].id : null}
                                                     placeholder="Inserisci Categoria" required
                                                     onChange={(event) => this.changeCategory(event.target.value)}>
                                                {this.listCategories()}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="name" label="Nome" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci Nome" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione zona"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="Inserisci descrizione della zona" type="textarea"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Regione" value={this.state.form_edit.region}
                                                     className="form-control" placeholder="Inserisci Regione"
                                                     type="select" required>
                                                {this.renderListRegion(this.state.form_edit.region)}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia"
                                                     value={this.state.form_edit.province}
                                                     className="form-control" placeholder="Inserisci Provincia"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Indirizzo"
                                                     value={this.state.form_edit.address} className="form-control"
                                                     placeholder="Inserisci Indirizzo" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Numero"
                                                     value={this.state.form_edit.number}
                                                     className="form-control" placeholder="Inserisci Numero"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" value={this.state.form_edit.city}
                                                     className="form-control" placeholder="Inserisci Città" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" value={this.state.form_edit.cap}
                                                     className="form-control" placeholder="Inserisci CAP" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="email" label="Email per le segnalazioni"
                                                     value={this.state.form_edit.email}
                                                     className="form-control"
                                                     placeholder="Inserisci email per le segnalazioni" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="email_cc" label="Email per altri destinatari (CC:)"
                                                     value={this.state.form_edit.email_cc}
                                                     className="form-control"
                                                     placeholder="Inserisci email per altri destinatari" type="text"/>
                                            <small>(separare le email multiple con la virgola)</small>
                                        </div>

                                    </div>
                                    {this.renderMap()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="success">Salva</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(ZoneShowcasePage));
