import {put} from 'redux-saga/effects';
import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const getProducts = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/list'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listTickets = async (id_society = null, page = 1, only_valid = false, search = null) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page, only_valid: only_valid};
        if (id_society !== null)
            data.id_society = id_society;
        if (search !== null)
            data.search = search;
        let response = await apiCall({method: 'POST', url: 'admin/ticket/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listAreaTickets = async (area, page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/list/area', data: {id: area, page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/add', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/edit', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const shareTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/share', data: data});
    } catch (error) {
        return false;
    }
}

export const ticketResidenceList = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/residence/list'});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ticketShareSociety = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/share/society/set', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ticketShareListSociety = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/share/society/list', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const disableTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/disable', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const listTicketResidenceType = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/residence/list'});
    } catch (error) {
        return false;
    }
}

export const addTicketResidenceType = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/residence/add', data: data});
    } catch (error) {
        return false;
    }
}

export const editTicketResidenceType = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/residence/edit', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteTicketResidenceType = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'DELETE', url: 'admin/ticket/residence/delete', data: id});
    } catch (error) {
        return false;
    }
}

export const listClientTickets = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/client/list', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const statsCoupon = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/coupon/stats', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const statsReferral = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/stats/referral', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listTicketClientCoupon = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/coupon/stats/ticket', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listClientTicketsCalendar = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/client/calendar', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoClientTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/client/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const refundClientTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/client/refund', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ticketClientChangeDate = async (id, date_start, date_end) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'admin/ticket/client/change/date',
            data: {id_ticket: id, date_start: date_start, date_end: date_end}
        });
    } catch (error) {
        return false;
    }
}

export const editClientTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/client/edit', data});
    } catch (error) {
        return false;
    }
}

export const editClientExtraDataTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/client/data/edit', data});
    } catch (error) {
        return false;
    }
}

export const listTicketPackage = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/package/list', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

/**
 * @deprecated
 */
export const addTicketPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/package/add', data: data});
    } catch (error) {
        return false;
    }
}

/**
 * @deprecated
 */
export const editTicketPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/package/edit', data: data});
    } catch (error) {
        return false;
    }
}

/**
 * @deprecated
 */
export const deleteTicketPackage = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/package/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

/**
 * @deprecated
 */
export const uploadTicketPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/ticket/package/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const listTicketClientPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/package/client/list', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

/** @deprecated */
export const listPackageAll = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/ticket/package/list/all', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setAvailabilityTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/edit/avaiability', data});
    } catch (error) {
        return false;
    }
}

export const setAvailabilityPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/package/edit/avaiability', data});
    } catch (error) {
        return false;
    }
}

export const sendNotify = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/notify', data: data});
    } catch (error) {
        return false;
    }
}

export const listNotifications = async (page = 1) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page};
        let response = await apiCall({method: 'POST', url: 'admin/notify/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ticketExtraDataAdd = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/data/add', data});
    } catch (error) {
        return false;
    }
}

export const ticketExtraDataDelete = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/ticket/data/delete', data});
    } catch (error) {
        return false;
    }
}


export const infoShopPackage = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/package/info', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const addShopPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/package/add', data: data});
    } catch (error) {
        return false;
    }
}

export const editShopPackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/package/edit', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteShopPackage = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/package/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const uploadImagePackage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/package/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const listShopPackage = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/package/list', data: {page: page}});
        if (typeof response.list !== 'undefined')
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const addShopPackageTicket = async (id_ticket, id_shop_package) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/ticket/shop/package/add',
            data: {id: id_ticket, id_shop_package: id_shop_package}
        });
        if (typeof response.ticket !== 'undefined')
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const removeShopPackageTicket = async (id_ticket, id_shop_package) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/ticket/shop/package/delete',
            data: {id: id_ticket, id_shop_package: id_shop_package}
        });
        if (typeof response.ticket !== 'undefined')
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const uploadImageTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/ticket/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}
